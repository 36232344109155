<script setup>
import { ref, onMounted, watch } from 'vue';
import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { tasksStore } from "@/stores/TasksStore.js";
import { useMapTools } from "@/Composables/MapTools.js";
import { measureToolStore }  from "@/stores/MeasureToolStore.js";

import LayersTab from "@/MapComponentsLayers/LayersTab.vue";
import SelectedTab from "@/MapComponentsLayers/SelectedTab.vue";
import ListTab from "@/MapComponentsLayers/ListTab.vue";
import SearchTab from "@/MapComponentsLayers/SearchTab.vue";
import GroupsTab from "@/MapComponentsLayers/GroupsTab.vue";
import HomeTab from "@/MapComponentsLayers/HomeTab.vue";
import ZonesTab from "@/MapComponentsLayers/ZonesTab.vue";

import ContestPill from "@/MapComponentsLayers/ContestPill.vue";
import SoaringSpotPill from "@/MapComponentsLayers/SoaringSpotPill.vue";
import GroupPill from "@/MapComponentsLayers/GroupPill.vue";
import TaskPill from "@/MapComponentsLayers/TaskPill.vue";

const app = appStateStore();
const settings = settingsStore();
const tasks = tasksStore();
const { goToSelected, hasFollows, goTo, followingLength } = useMapTools();
const measureToolData = measureToolStore();
import { formatTimeFromStamp, renderDistance } from '../Helpers.js';

import interact from 'interactjs'

const sidepanel = ref();


import { Bars3Icon, XMarkIcon, MagnifyingGlassIcon, ViewfinderCircleIcon, HomeIcon } from "@heroicons/vue/24/outline";


// watch for selected
watch(() => app.selected, (currentValue, oldValue) => {
	if (settings.sideBarPage=='selected') {
		if (currentValue==null) {
			settings.sideBarPage = 'list';
		}
	} else {
		// select the selected tab if selecting someone
		if (currentValue!==null) {
			settings.sideBarPage = 'selected';
		}
	}
});

function closeSelected() {
	settings.sideBarPage = 'list';
	app.selected=null;
}


interact('.side-panel')
	.resizable({
		edges: {
			top   : true,		// Use pointer coords to check for resize.
			left  : false,		// Disable resizing from left edge.
			bottom: false,		// Resize if pointer target matches selector
			right : false		// Resize if pointer target is the given Element
		},
		listeners: {
			move: function (event) {

				let { x, y } = event.target.dataset

				//x = (parseFloat(x) || 0) + event.deltaRect.left
				y = (parseFloat(y) || 0) + event.deltaRect.top

				if (event.rect.height<45) return;

				Object.assign(event.target.style, {
					//width: `${event.rect.width}px`,
					height: `${event.rect.height}px`,
					//transform: `translate(${x}px, ${y}px)`
				});

				settings.legendPanelHeight = event.rect.height;
				//settings.legendPanelWidth = event.rect.width;

				Object.assign(event.target.dataset, { x, y })
			}
		}
	});


onMounted(() => {
	// console.log('Created Side Panel');
	// console.log('set height on mount to ' + settings.legendPanelHeight );

	// check we're in sensible limits
	if (settings.legendPanelHeight >= window.innerHeight-100) {
		settings.legendPanelHeight = Math.round(window.innerHeight/3);
	}
	if (settings.legendPanelHeight < 10) {
		settings.legendPanelHeight = Math.round(window.innerHeight/3);
	}

	// set the initial size
	Object.assign(sidepanel.value.style, {
		height: settings.legendPanelHeight + 'px',
	});
});
</script>



<template>

	<div ref="sidepanel" class="side-panel bg-gray-100 dark:bg-gray-800 text-black dark:text-white pointer-events-auto shadow-xl touch-none 
		w-full sm:w-fit sm:rounded-xl flex flex-col overflow-hidden  mt-2
		min-w-[300px]
		">


		<div class="gap-2 content-start items-center h-2">
			<div class="flex flex-col gap-1 pt-1 flex-grow  justify-center items-center">

				<div class="h-1 w-10 bg-gray-300 dark:bg-gray-600 shadow-inner rounded-full"></div>

			</div>
		</div>


		<div class="pointer-events-auto mx-2 mb-1 mt-1" v-if="app.compSlug">
			<ContestPill :slug="app.compSlug" />
		</div>

		<div class="pointer-events-auto mx-2 mb-1 mt-1" v-if="settings.contestID">
			<SoaringSpotPill  />
		</div>

		<div class="pointer-events-auto mx-2 mb-1 mt-1" v-if="app.groupSlug">
			<GroupPill :slug="app.groupSlug"></GroupPill>
		</div>
		
		<div class="pointer-events-auto mx-2 mb-1 mt-1" v-if="tasks.activeTasks().length>0">
			<TaskPill />
		</div>


		<div class="flex gap-2 border-b border-b-gray-300 dark:border-b-black">

			<div class="header-row flex gap-2 w-full items-center pt-1 pl-1 ">


				<div class="flex flex-wrap items-center gap-1 text-sm">
				
					<div class="rounded-t-lg cursor-pointer hover:dark:bg-gray-300 hover:dark:text-black hover:bg-gray-700 hover:text-white px-1 py-1 flex gap-1 items-center"
						:class="[settings.sideBarPage=='home' ? 'dark:bg-gray-200 dark:text-black bg-gray-800 text-white' : '']"
						@click="settings.sideBarPage='home'">
						<HomeIcon class="size-5" />
					</div>

					<div class="rounded-t-lg cursor-pointer hover:dark:bg-gray-300 hover:dark:text-black hover:bg-gray-700 hover:text-white px-2 py-1 flex gap-1 items-center"
						:class="[settings.sideBarPage=='search' ? 'dark:bg-gray-200 dark:text-black bg-gray-800 text-white' : '']"
						@click="settings.sideBarPage='search'">
						<MagnifyingGlassIcon class="size-5" />
						<span class="hidden xs:inline">
							{{ $t('app.search') }}
						</span>
					</div>


					<!-- <div class="rounded-t-lg cursor-pointer hover:dark:bg-gray-300 hover:dark:text-black hover:bg-gray-700 hover:text-white px-2 py-1 flex gap-1 items-center"
						:class="[settings.sideBarPage=='groups' ? 'dark:bg-gray-200 dark:text-black bg-gray-800 text-white' : '']"
						@click="settings.sideBarPage='groups'">
						Groups
					</div> -->

					<div class="rounded-t-lg cursor-pointer hover:dark:bg-gray-300 hover:dark:text-black hover:bg-gray-700 hover:text-white px-2 py-1 flex gap-1 items-center"
						:class="[settings.sideBarPage=='list' ? 'dark:bg-gray-200 dark:text-black bg-gray-800 text-white' : '']"
						@click="settings.sideBarPage='list'">
						{{ $t('app.list') }}
						<button v-show="settings.sideBarPage=='list'" :title="$t('legend.legend-settings')" class="hover:bg-gray-200 hover:text-black dark:hover:bg-gray-800 dark:hover:text-white rounded-full p-0.5 ml-0.5" @click="app.show='legend-settings'">
							<Bars3Icon class="size-4" />
						</button>
					</div>

					<div v-if="app.selected" class="rounded-t-lg cursor-pointer hover:dark:bg-gray-300 hover:dark:text-black hover:bg-gray-700 hover:text-white pl-2 pr-1 py-1 flex  items-center"
						:class="[settings.sideBarPage=='selected' ? 'dark:bg-gray-200 dark:text-black bg-gray-800 text-white' : '']"
						@click="settings.sideBarPage='selected'">
						<span :class="app.selected ? '' : 'opacity-50'">
							{{ $t('app.selected') }}
						</span>

						<button class="hover:bg-gray-200 hover:text-black dark:hover:bg-gray-800 dark:hover:text-white rounded-full p-0.5 ml-0.5" :title="$t('app.close')" @click.stop="closeSelected();">
							<XMarkIcon class="size-4" />
						</button>

					</div>

					<div class="rounded-t-lg cursor-pointer hover:dark:bg-gray-300 hover:dark:text-black hover:bg-gray-700 hover:text-white px-2 py-1 flex gap-1 items-center"
						:class="[settings.sideBarPage=='view' ? 'dark:bg-gray-200 dark:text-black bg-gray-800 text-white' : '']"
						@click="settings.sideBarPage='view'">
						{{ $t('app.map') }}
					</div>

					<div v-if="settings.sideBarPage=='zones'" class="rounded-t-lg cursor-pointer hover:dark:bg-gray-300 hover:dark:text-black hover:bg-gray-700 hover:text-white px-2 py-1 flex gap-1 items-center"
						:class="[settings.sideBarPage=='zones' ? 'dark:bg-gray-200 dark:text-black bg-gray-800 text-white' : '']"
						@click="settings.sideBarPage='zones'">
						Zones
					</div>

				</div>

				<div class="flex-grow flex justify-end mr-2 pb-1">



					<button
						:title="$t('app.follow')"
						:class="[settings.pauseFollowing ? 'round-button' : 'round-button-selected !bg-green-700 dark:text-white ']"
						class="cursor-pointer flex gap-1 items-center !p-1 text-xs"
						@click="settings.toggleFollow()">
						<ViewfinderCircleIcon class="size-4" /> 

						<span v-if="followingLength()>0">
							<span>{{followingLength()}}</span>
						</span>
					</button>
				</div>

			</div>


		</div>

		<div class="overflow-y-scroll w-full border-t border-t-white dark:border-t-gray-700">

			<HomeTab v-show="settings.sideBarPage=='home'"></HomeTab>
			<LayersTab v-show="settings.sideBarPage=='view'"></LayersTab>
			<SelectedTab v-show="settings.sideBarPage=='selected'"></SelectedTab>
			<ListTab v-show="settings.sideBarPage=='list'"></ListTab>
			<SearchTab v-show="settings.sideBarPage=='search'"></SearchTab>
			<GroupsTab v-show="settings.sideBarPage=='groups'"></GroupsTab>
			<ZonesTab v-if="settings.sideBarPage=='zones'"></ZonesTab>

		</div>

	</div>
</template>