<script setup>
import { ref, computed } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";
import { skySightStore } from "@/stores/SkySightStore.js";

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon, BellAlertIcon } from "@heroicons/vue/24/outline";

const app = appStateStore();
const settings = settingsStore();
const skysight = skySightStore();


function setStyle(style) {
	if (app.subscribed) {
		settings.baseMapID = style.id;
	} else {
		settings.freeMapID = style.id;
	}
	app.style=style;
}

function getMapID() {
	if (app.subscribed) return settings.baseMapID;
	return settings.freeMapID;
}

// get the currently selected style name
var currentName = computed(() => {
	var style =  mapStyles.filter(obj => {
		return obj.id === settings.baseMapID;
	});
	return style[0].name;
});

var skySightMenu = [
	{id: 'sat', label: t('layers-menu.live-satellite'), pro: false},
]

var recentMenu = [
	{id: 15, label: t('layers-menu.15-label'), alt: t('layers-menu.15-alt'), pro: false},
	{id: 60, label:t('layers-menu.60-label'), alt: t('layers-menu.60-alt'), pro: false},
	{id: 360, label:t('layers-menu.360-label'), alt: t('layers-menu.360-alt'), pro: false},
	{id: 720, label:t('layers-menu.720-label'), alt: t('layers-menu.720-alt'), pro: true},
	{id: 1440, label: t('layers-menu.1440-label'), alt: t('layers-menu.1440-alt'), pro: true},
];


</script>

<template>

<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
<div class=" flex p-2 sm:px-8 sm:py-4 flex-col w-full overflow-hidden">
<div class="frosted-panel flex flex-col overflow-hidden">

	<div class="flex items-center px-4 pt-4 pb-2 gap-4">

		<button title="close" class="round-button" @click="app.show=null">
			<XMarkIcon class="w-6 h-6" />
		</button>

		<h2 class="menu-title flex-grow ">{{ $t('layers-menu.layers') }}</h2>

		<div class="size-6"></div>
		<!-- <button title="close" class="round-button" @click="app.show=null">
			<CogIcon class="w-6 h-6" />
		</button> -->

	</div>


	<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6">


		<a href="/upgrade" v-if="!app.fromAppStore && !app.subscribed" class="mb-4 flex items-center gap-2 justify-center">
			<LockClosedIcon class="h-4 w-4" /> Upgrade to Pro to unlock
		</a>

		<div class="flex flex-col gap-6 w-full">

			<div class="menu-section w-full flex flex-wrap justify-between p-4 gap-4">

				<div v-for="mapStyle in mapStyles" :key="mapStyle.id">

					<div v-if="app.subscribed || mapStyle.pro==false">
						<button  class="border-gray-400 shadow-lg shadow-gray-500 dark:shadow-black w-[60px] h-[60px] rounded-lg bg-gray-700 cursor-pointer items-center content-center justify-center flex" 
							style="background-size: 100%" 
							v-bind:style="{ 'background-image': 'url(/images/map-icons/map-icons_' + mapStyle.id + '.png)' }"
							v-on:click="setStyle(mapStyle); app.show=null;"
							@mouseover="hoverName = mapStyle.name"
							@mouseleave="hoverName = ''">
								<div v-show="getMapID() == mapStyle.id" class="rounded-full p-2 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-200 shadow-lg shadow-gray-800">
									<CheckIcon class="h-4 w-4" />
								</div>
						</button>
					</div>

					<div v-if="!app.subscribed && mapStyle.pro==true">
						<div class="shadow-lg shadow-gray-500 dark:shadow-black w-[60px] h-[60px] rounded-lg bg-gray-700 cursor-pointer items-center content-center justify-center flex" 
							style="background-size: 100%" 
							v-bind:style="{ 'background-image': 'url(/images/map-icons/map-icons_' + mapStyle.id + '.png)' }"
							@mouseover="hoverName = mapStyle.name"
							@mouseleave="hoverName = ''"
							>
								<div v-if="mapStyle.id!='osm'" class="rounded-full p-2 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-200  shadow-lg shadow-gray-800">
									<LockClosedIcon class="h-4 w-4" />
								</div>
								<div v-show="getMapID() == mapStyle.id" class="rounded-full p-2 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-200  shadow-lg shadow-gray-800">
									<CheckIcon class="h-4 w-4" />
								</div>
								
						</div>
					</div>

				</div>

			</div>

			<div class="menu-section w-full">

				<div class="menu-row flex-wrap justify-between">

					<div v-if="app.subscribed" class="flex gap-4 items-center">
						<ToggleSwitch :title="$t('layers-menu.mountains')" v-model="settings.showMountainsLayer" @click="settings.showMountainsLayer = !settings.showMountainsLayer" id="showMountainsLayer"></ToggleSwitch>
						<label for="showMountainsLayer" class="flex-grow cursor-pointer">{{ $t('layers-menu.mountains') }}</label> 
					</div>

					<div v-if="!app.subscribed" class="flex gap-4 items-center">
						<ToggleSwitch :title="$t('layers-menu.mountains')" v-model="settings.showMountainsLayer" disabled id="showMountainsLayer"></ToggleSwitch>
						<label  class="flex-grow  opacity-50">{{ $t('layers-menu.mountains') }}</label> 
						<a href="/upgrade"><LockClosedIcon class="h-4 w-4" v-show="!app.subscribed" /></a>
					</div>

				</div>




				<div class="menu-row flex-wrap justify-between">

					<ToggleSwitch :title="$t('layers-menu.icons')" v-model="settings.showIcons" @click="settings.showIcons = !settings.showIcons" id="showIcons"></ToggleSwitch>
					<label for="showIcons" class="flex-grow cursor-pointer">{{ $t('layers-menu.icons') }}</label> 

					<div class="flex flex-col gap-1">
						<div class="text-sm text-gray-600 dark:text-gray-400">
							{{ $t('layers-menu.hide-markers-older-than') }}
						</div>

						<div class="flex flex-wrap gap-2">
							<button v-for="item in recentMenu"
								:title="item.alt"
								:key="item.id"
								:disabled="!app.subscribed && item.pro"
								class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer disabled:opacity-50 flex items-center gap-1"
								@click="settings.filterMaxAge = item.id; app.show=null;"
								:class="settings.filterMaxAge==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
									{{ item.label }}
									<LockClosedIcon class="h-4 w-4" v-show="!app.subscribed && item.pro" />
							</button>
						</div>
					</div>

					<label for="filterMaxAltitude " class="flex justify-between items-center w-full">
						{{$t('layers-menu.max-altitude')}}

						<select  name="filterMaxAltitude" id="filterMaxAltitude" class="input text-sm" 
							v-if="settings.altitudeUnits=='feet'" v-model="settings.filterMaxAltitude" @change="app.restartLoop=true">
							<option value="1000">1000ft</option>
							<option value="3000">3000ft</option>
							<option value="5000">5000ft</option>
							<option value="10000">10000ft</option>
							<option value="15000">15000ft</option>
							<option value="20000">20000ft</option>
							<option value="null">{{$t('layers-menu.no-limit')}}</option>
						</select>
						<select  name="filterMaxAltitude" id="filterMaxAltitude" class="input text-sm" 
							v-if="settings.altitudeUnits=='meters'" v-model="settings.filterMaxAltitude" @change="app.restartLoop=true">
							<option value="500">500m</option>
							<option value="1000">1000m</option>
							<option value="2000">2000m</option>
							<option value="3000">3000m</option>
							<option value="5000">5000m</option>
							<option value="10000">10000m</option>
							<option value="null">{{$t('layers-menu.no-limit')}}</option>
						</select>
					</label>



				</div>

				<div class="menu-row">

					<ToggleSwitch :title="$t('layers-menu.labels')" v-model="settings.showLabels" @click="settings.showLabels = !settings.showLabels" id="showLabels"></ToggleSwitch>
					<label for="showLabels" class="flex-grow cursor-pointer">{{ $t('layers-menu.labels') }}</label> 

					<button @click="app.showBack.push('layers-menu'); app.show='labels-menu';"
						class="flex items-center gap-2">
						{{ $t('layers-menu.options') }}
						<button class="round-button" >
							<ArrowRightIcon class="w-4 h-4" />
						</button>
					</button>
				</div>


				<div class="menu-row">

					<div class="whitespace-nowrap flex items-center gap-2">
						<ToggleSwitch v-model="settings.showAirspace" @click="settings.showAirspace = !settings.showAirspace" id="showAirspace"></ToggleSwitch>
						<label for="showAirspace" class="flex-grow cursor-pointer">{{ $t('layers-menu.airspace') }}</label> 
					</div>
				</div>



				<div class="menu-row">


					<div class="flex flex-col gap-4">

						<div class="whitespace-nowrap flex items-center gap-2">
							<ToggleSwitch v-model="settings.showLocations" id="showLocations" @click="settings.showLocations = !settings.showLocations"></ToggleSwitch>
							<label for="showLocations" class="flex-grow cursor-pointer">{{ $t('layers-menu.all-points-of-interest') }}</label>
						</div>



						<div class="flex flex-wrap gap-x-6 gap-y-2 ml-6"
							:class="[settings.showLocations ? '' : 'opacity-30']">

							<div class="whitespace-nowrap flex items-center gap-2">
								<ToggleSwitch v-model="settings.showAirports" id="showAirports" @click="settings.showAirports = !settings.showAirports"></ToggleSwitch>
								<label for="showAirports" class="flex-grow cursor-pointer flex gap-1">
									<img src="/images/icons/runway.png" class="size-6" alt="Runway Icon">
									{{ $t('layers-menu.airports') }}
								</label>
							</div>


							<div class="whitespace-nowrap flex items-center gap-2">
								<ToggleSwitch v-model="settings.showSmallAirports" id="showSmallAirports" @click="settings.showSmallAirports = !settings.showSmallAirports"></ToggleSwitch>
								<label for="showSmallAirports" class="flex-grow cursor-pointer flex gap-1">
									<img src="/images/icons/minor-runway.png" class="size-6" alt="Runway Icon">
									{{ $t('layers-menu.minor-airfields') }}

								</label>
							</div>

							<div class="whitespace-nowrap flex items-center gap-2">
								<ToggleSwitch v-model="settings.showHelipads" id="showHelipads" @click="settings.showHelipads = !settings.showHelipads"></ToggleSwitch>
								<label for="showHelipads" class="flex-grow cursor-pointer flex gap-1">
									<img src="/images/icons/helipad.png" class="size-6" alt="Runway Icon">
									{{ $t('layers-menu.helipads') }}

								</label>
							</div>

							<div class="whitespace-nowrap flex items-center gap-2">
								<ToggleSwitch v-model="settings.showLaunchSites" id="showLaunchSites" @click="settings.showLaunchSites = !settings.showLaunchSites"></ToggleSwitch>
								<label for="showLaunchSites" class="flex-grow cursor-pointer flex gap-1">
									<img src="/images/icons/launch_site.png" class="size-6" alt="Runway Icon">
									{{ $t('layers-menu.launch-sites') }}

								</label>
							</div>

						</div>
					</div>
				</div>


				<div class="menu-row">

					<div class="whitespace-nowrap flex items-center gap-2">
						<ToggleSwitch v-model="settings.showRainRadar" id="showRainRadar" @click="settings.showRainRadar = !settings.showRainRadar"></ToggleSwitch>
						<label for="showRainRadar" class="flex-grow cursor-pointer">{{ $t('layers-menu.rain-radar') }}</label>
					</div>


				</div>

				<div class="menu-row">

					<div class="whitespace-nowrap flex items-center gap-2">
						<ToggleSwitch v-model="settings.showTails" id="showRainRadar" @click="settings.showTails = !settings.showTails"></ToggleSwitch>
						<label for="showTails" class="flex-grow cursor-pointer">{{ $t('layers-menu.show-tails') }}</label>
					</div>


				</div>

			</div>




			<div class="menu-section w-full">

				<div class="menu-row justify-between flex-wrap" v-if="!app.skysightLoggedIn">

					<img src="/images/source-icons/skysight.png" class="w-8">

					<div class="whitespace-nowrap flex flex-grow items-center gap-2">
						<ToggleSwitch disabled v-model="settings.showSkySight" id="showSkySight"></ToggleSwitch>
						<label for="showSkySight" class="flex-grow cursor-pointer disabled-text">SkySight</label>
					</div>

					<button class="link" @click="app.showBack.push('layers-menu'); app.show='skysight-menu'">
						{{ $t('layers-menu.login-required') }}
					</button>
				</div>

				<div class="menu-row justify-between flex-wrap" v-if="app.skysightLoggedIn">

					<div class="whitespace-nowrap flex flex-col   gap-2">
						
						<div class="flex items-center gap-4">
							<div class="h6"><img src="/images/source-icons/skysight.png" class="w-8"></div>

							<label for="showSkySight" class=" cursor-pointer " @click="settings.showSkySight = !settings.showSkySight">

								<ToggleSwitch v-model="settings.showSkySight" id="showSkySight"></ToggleSwitch>

								{{ $t('layers-menu.skysight-forecast') }}
							</label>
						</div>

						<div class="flex flex-wrap gap-2 items-center" v-if="settings.showSkySight">

							<button v-for="item in skysight.layers"
								:title="item.name"
								:key="item.id"
								:disabled="!app.subscribed && item.pro"
								class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer disabled:opacity-50 flex items-center gap-1 text-sm"
								@click="settings.skySightLayer = item.id;"
								:class="settings.skySightLayer==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">
									{{ item.name }}
									<LockClosedIcon class="h-4 w-4" v-show="!app.subscribed && item.pro" />
							</button>
						</div>

						<div class="flex gap-2 items-center" v-if="settings.showSkySight">
							<label for="skysightOpacity">Opacity</label>
							<span class="text-gray-600 dark:text-gray-400 text-sm">Transparent</span>
							<input type="range" id="skysightOpacity" name="skysightOpacity" min=".1" max="1" step="0.1" v-model="settings.skySightForecastOpacity" />
							<span class="text-gray-600 dark:text-gray-400 text-sm">Solid</span>
							<div class="text-white bg-gray-600 rounded p-1 text-xs">
								{{settings.skySightForecastOpacity * 100}}%
							</div>
							
						</div>


					</div>
				</div>
				<div class="menu-row" v-if="app.skysightLoggedIn">

					<img src="/images/map-icons/skysight-satellite.png" class="w-8 rounded-lg">

					<div class="flex flex-wrap gap-2 flex-grow">

						<ToggleSwitch id="showSkySightSat" 
						v-model="settings.showSkySightSat" 
						@click="settings.showSkySightSat = !settings.showSkySightSat"></ToggleSwitch>

						<label for="showSkySightSat" class=" cursor-pointer " 
						>
							{{ $t('layers-menu.live-satellite') }}
						</label>
					</div>

				</div>
			</div>


			<div class="menu-section w-full">

				<div class="menu-row justify-between flex-wrap" v-if="app.subscribed">

					<img src="/images/icons/spiral7.png" class="size-8">

					<div class="whitespace-nowrap flex flex-grow items-center gap-2">
						<ToggleSwitch title="Toggle Thermals" v-model="settings.showThermals" id="showThermals" @click="settings.showThermals = !settings.showThermals"></ToggleSwitch>
						<label for="showThermals" class="flex-grow cursor-pointer">{{ $t('layers-menu.climb-markers') }}</label> 
					</div>

					

				</div>

				<div class="menu-row justify-between flex-wrap" v-if="app.subscribed">

					<img src="/images/hex-grid.png" class="size-8">

					<div class="whitespace-nowrap flex flex-grow items-center gap-2">
						<ToggleSwitch title="Toggle Thermal Heights" v-model="settings.showThermalHeights" @click="settings.showThermalHeights = !settings.showThermalHeights" id="showThermalHeights"></ToggleSwitch>
						<label for="showThermalHeights" class="flex-grow cursor-pointer">{{ $t('layers-menu.climb-heights') }}</label> 
					</div>

				</div>


				<div class="menu-row justify-between flex-wrap" v-if="!app.subscribed">

					<img src="/images/icons/spiral7.png" class="size-8">

					<div class="whitespace-nowrap flex flex-grow items-center gap-2">
						<ToggleSwitch disabled title="Toggle Thermals" id="showThermals"></ToggleSwitch>
						<label for="showThermals" class="flex-grow disabled-text">{{ $t('layers-menu.climb-markers') }}</label>
					</div> 

					<div class="flex items-center gap-4">

						<a href="/upgrade" v-if="!app.fromAppStore && !app.subscribed" class="flex gap-2 text-sm items-center">
							<button title="Upgrade to Pro" class="round-button">
								<LockClosedIcon class="h-4 w-4" />
							</button>
						</a>

						<div class="round-button" v-if="app.fromAppStore">
							<LockClosedIcon class="h-4 w-4"  />
						</div>
					
					</div>

				</div>

				<div class="menu-row justify-between flex-wrap" v-if="!app.subscribed">

					<img src="/images/hex-grid.png" class="size-8">

					<div class="whitespace-nowrap flex flex-grow items-center gap-2">
						<ToggleSwitch disabled title="Toggle Thermal Heights" id="showThermalHeights"></ToggleSwitch>
						<label for="showThermalHeights" class="flex-grow disabled-text">{{ $t('layers-menu.climb-heights') }}</label> 
					</div>

					<div class="flex items-center gap-4">

						<a href="/upgrade" v-if="!app.fromAppStore && !app.subscribed" class="flex gap-2 text-sm items-center">
							<button title="Upgrade to Pro" class="round-button">
								<LockClosedIcon class="h-4 w-4" />
							</button>
						</a>

						<div class="round-button" v-if="app.fromAppStore">
							<LockClosedIcon class="h-4 w-4"  />
						</div>

					</div>
				</div>


				<div class="menu-row justify-between flex-wrap" v-if="app.subscribed">

					<div class="relative  -mx-1">
						<img src="/images/icons/wind6.png" class="size-10">
						<div class="absolute top-3 left-4 text-xs text-white font-bold">5</div>
					</div>

					<div class="whitespace-nowrap flex items-center gap-2 flex-grow">
						<ToggleSwitch v-model="settings.showWeatherStations" id="showWeatherStations" @click="settings.showWeatherStations = !settings.showWeatherStations"></ToggleSwitch>
						<label for="showWeatherStations" class="flex-grow cursor-pointer">{{ $t('layers-menu.live-wind') }}</label>
					</div>

				</div>

				<div class="menu-row justify-between flex-wrap" v-if="app.subscribed">

					<div class="size-6 mx-1 bg-blue-600 bg-opacity-10 border-2 border-blue-700  rounded-full"></div>

					<div class="whitespace-nowrap flex items-center gap-2 flex-grow">
						<ToggleSwitch v-model="settings.showZones" id="showZones" @click="settings.showZones = !settings.showZones"></ToggleSwitch>
						<label for="showZones" class="flex-grow cursor-pointer">{{ $t('layers-menu.zones') }}</label>
					</div>

				</div>


				<div class="menu-row justify-between flex-wrap" v-if="!app.subscribed">

					<img src="/images/icons/wind6.png" class="size-10">

					<div class="whitespace-nowrap flex flex-grow items-center gap-2">
						<ToggleSwitch disabled v-model="settings.showWeatherStations" id="showWeatherStations"></ToggleSwitch>
						<label for="showWeatherStations" class="flex-grow cursor-pointer disabled-text">{{ $t('layers-menu.live-wind') }}</label>
					</div>


					<div class="flex items-center gap-4">
						<a href="/upgrade" v-if="!app.fromAppStore && !app.subscribed" class="flex gap-2 text-sm items-center">
							<button title="Upgrade to Pro" class="round-button">
								<LockClosedIcon class="h-4 w-4" />
							</button>
						</a>

						<div class="round-button" v-if="app.fromAppStore">
							<LockClosedIcon class="h-4 w-4"  />
						</div>
					</div>
				</div>


			</div>


		</div>

	</div>

</div>
</div>
</div>

</template>